import React from "react"
import { graphql } from 'gatsby'
import HubspotForm from "react-hubspot-form"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { htmlDecode } from "../Utils"
import {Email} from "react-obfuscate-email";
import {StaticImage} from "gatsby-plugin-image";

const SubscribePageTemplate = ({ pageContext }) => {
  const seoTitle = (pageContext.seo) ? pageContext.seo.seoTitle : pageContext.title
  const seoDesc = (pageContext.seo) ? pageContext.seo.seoDescription : ''
  const featuredImageUrl = (pageContext.featuredImage?.formats?.large) ? pageContext.featuredImage.formats.large.url : pageContext.featuredImage.url
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';

  let formId = null
  if (pageContext.form) {
    const split1 = pageContext.form.split('formId:')
    const split2 = split1[1].split("\n")
    formId = split2[0].replaceAll('"', '')
  }

  return (
    <Layout classNames="subscribe-template" style={{paddingTop: '60px'}}>
      <Seo title={ seoTitle } seoTitle={ seoTitle } seoDescription={ seoDesc } />
      <section className="banner-wrap">
        <div id="banner" className="banner">
          <div className="banner-bg-image col-12 ph0 small" style={{backgroundImage: `url("${featuredImageUrl.includes(cdnUrl) ? featuredImageUrl : cdnUrl + featuredImageUrl }")`, opacity: 1}}>
          </div>
          <div className="banner-overlay vert-gradient" />
          <div className="banner-inside aic jcc tac">
            <div className="col-12 col-sm-8 col-md-8 banner-text ml0 m-left text-left blue-overlay vert-gradient" dangerouslySetInnerHTML={{ __html: pageContext.heroContent }} />
          </div>
        </div>
      </section>
      <section className="content-wrap">
          <div className="form_with_content_block pb5">
            <div className="patternBg">
              <StaticImage src="https://dlyog079eaher.cloudfront.net/uploads/Checkered_Arrows_Bottom_7b89afba4c.png" alt="background" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8 col-md-10 offset-md-1 offset-lg-2 no-gutter-lg-left subform sub78">
                  <div className="form_with_content_block__form-wrap">
                    {(pageContext.form && formId) &&
                    <div className="pt5 ph6 pb5 tac">
                      <HubspotForm
                          portalId="2718424"
                          css=""
                          cssClass="hbspt-form"
                          region="na1"
                          formId={ formId }
                          loading={ <div className="form-loading"><h3>Loading...</h3></div> }
                      />
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
    </Layout>
  )
}


export default SubscribePageTemplate
